<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <div>
            <TabsComp :tabs="tabs" />
            <v-row class="mainrow mt-2 mb-2" :style="`margin-inline-start:2px;direction:`+lang.dir+`;margin-top:-8px !important;`">
                <v-row class="mt-5">
                    <v-col cols="12" md="12">
                        <form autocomplete="off">
                            <div class="searchTop">
                                <div class="searchBar">
                                    <div style="width:200px">
                                        <div class="mobileFont">{{lang.chose_from_list}} {{lang.start_date}}</div>
                                        <b-input-group>
                                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="$store.state.monthsList" />
                                            <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                                        </b-input-group>
                                    </div>
                                    <div>
                                        <div>&nbsp;</div>
                                        <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;" class="btnMobile"  @click="getCurrentReport()">{{lang.search}}</b-button>
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :style="`direction:${lang.dir};padding-inline-end:30px;`">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack text-center" colspan="10">{{ lang.day_report }} {{ sdate }}</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center" style="width:50%;" colspan="5">{{ lang.income_type }}</th>
                                    <th class="backBlack text-center" style="width:50%;" colspan="5">{{ lang.expnese }}</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center" style="">{{ lang.ammount }}</th>
                                    <th class="backBlack text-center">{{ lang.description }}</th>
                                    <th class="backBlack text-center" style="">{{ lang.invoice }}</th>
                                    <th class="backBlack text-center" style="">{{ lang.cash }}</th>
                                    <th class="backBlack text-center" style="">{{lang.span}}</th>
                  
                                    <th class="backBlack text-center" style="">{{ lang.ammount }}</th>
                                    <th class="backBlack text-center">{{lang.expense}}</th>
                                    <th class="backBlack text-center" style="">{{ lang.invoice }}</th>
                                    <th class="backBlack text-center" style="">{{ lang.cash }}</th>
                                    <th class="backBlack text-center" style="">{{lang.span}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in reportRow" :key="index"> 
                                    <td style="text-align:center;">{{ item.income.amount }}</td>
                                    <td style="text-align:center;">
                                        <v-simple-table>
                                            <tr v-for="(itm, indx) in item.income.item_name" :key="indx">
                                                <td style="border:none !important;width:70px;text-align:center;">{{ itm.amount }}</td>
                                                <td :style="`border:none !important;text-align:${lang.algin};`">{{ itm.item_name }}</td>
                                            </tr>
                                        </v-simple-table>
                                    </td>
                                    <td style="text-align:center;">{{ item.income.invoiceid }}</td>
                                    <td style="text-align:center;">{{ item.income.cash }}</td>
                                    <td style="text-align:center;">{{ item.income.other }}</td>

                                    <td style="text-align:center;">{{ item.expense.amount }}</td>
                                    <td style="text-align:center;">
                                        <v-simple-table>
                                            <tr v-for="(itm, indx) in item.expense.item_name" :key="indx">
                                                <td style="border:none !important;width:70px;text-align:center;">{{ itm.amount }}</td>
                                                <td :style="`border:none !important;text-align:${lang.algin};`">{{ itm.item_name }}</td>
                                            </tr>
                                        </v-simple-table>
                                    </td>
                                    <td style="text-align:center;">{{ item.expense.invoiceid }}</td>
                                    <td style="text-align:center;">{{ item.expense.cash }}</td>
                                    <td style="text-align:center;">{{ item.expense.other }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class=" text-center">{{ income_total }}</th>
                                    <th class="backBlack text-center">{{ lang.income_total }}</th>
                                    <th class="backBlack text-center"></th>
                                    <th class="backBlack text-center"></th>
                                    <th class="backBlack text-center"></th>
                                    <th class=" text-center">{{ expense_total }}</th>
                                    <th class="backBlack text-center">{{lang.epxpense_total}}</th>
                                    <th class="backBlack text-center"></th>
                                    <th class="backBlack text-center"></th>
                                    <th class="backBlack text-center"></th>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                        <br>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack text-center">{{ lang.previews_balance }}</th>
                                    <th class="text-center">0</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center">{{lang.income}}</th>
                                    <th class="text-center">{{income_total}}</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center">{{lang.cash}}</th>
                                    <th class="text-center">{{cash_total}}</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center">{{lang.span}}</th>
                                    <th class="text-center">{{other_total}}</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center">{{ lang.expense }}</th>
                                    <th class="text-center">{{expense_total}}</th>
                                </tr>
                                <tr>
                                    <th class="backBlack text-center">{{lang.today_balance}} {{ sdate }}</th>
                                    <th class="text-center">{{balance}}</th>
                                </tr>
                            </thead>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-row>
        </div>
        <br><br>
        <Footer style="margin-top:20px;" />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ExportsPrint from '@/components/exports-print.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default{
    components: {HeaderPortrate, Footer, ExportsPrint, TabsComp},
    data() {
        return {
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            reportRow: [],
            income_total: 0,
            expense_total: 0,
            cash_total: 0,
            other_total: 0,
            balance: 0,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function(){
            let day = this.sd.day;
            let month = this.sd.month;
            if(this.sd.day <10) day = "0"+day;
            if(this.sd.month <10) month = "0"+month;
            return this.sd.year + '-' + month + '-' + day
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
          
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            // console.log("menu",this.$store.getters['state'].licenseType)
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      },

    },
    methods: {
        collectSearch(){
          return {
              module: 'dailyRep',
              elements: {
                  sdate: this.sdate,
              }
          }
      },
        getCurrentReport()
        {
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            const cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('type','getFullDayReport'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[sdate]',this.sdate);
            axios.post(
            this.$store.state.SAMCOTEC.r_path,
                post
            ).then((response) => {
                this.reportRow = response.data.results.data;
                this.income_total = response.data.results.income_total;
                this.expense_total = response.data.results.expense_total;
                this.cash_total = response.data.results.cash_total;
                this.other_total = response.data.results.other_total;
                this.balance = response.data.results.balance;
            })
        }
    },
    created() {
        this.getCurrentReport();
    }
}
</script>